import { IGetRetireRequestDTO, IRetireRECs } from "@/models/Retire";
import { IResponse } from "@/services/axios/entities";
import { catchAxiosError } from "@/services/axios/error";
import HttpClient from "@/services/axios/instance";
import { getEnv } from "@/utils/storage";

const RETIRES = "Retires";
const env = getEnv();
export class RetireAPI extends HttpClient {
  constructor() {
    super(env?.VUE_APP_DOMAIN_API_SERVER);
  }

  public retireRECs = async (params: IRetireRECs): Promise<IResponse> => {
    const response: IResponse = await this.instance
      .post(RETIRES, params)
      .catch(catchAxiosError);

    return response;
  };

  public getRetireRECs = async (
    params: IGetRetireRequestDTO
  ): Promise<IResponse> => {
    const response: IResponse = await this.instance
      .get(`${RETIRES}/${params.Year}`, { params })
      .catch(catchAxiosError);

    return response;
  };
}
