import { IResponse } from "./axios/entities";
import { catchAxiosError } from "./axios/error";
import HttpClient from "./axios/instance";
import { getEnv } from "@/utils/storage";

const Devices = "Devices";
const env = getEnv();
export class DevicesAPI extends HttpClient {
  constructor() {
    super(env?.VUE_APP_DOMAIN_API_SERVER);
  }

  public getDevices = async (params?: {
    FacilityId?: string;
  }): Promise<IResponse> => {
    const response: IResponse = await this.instance
      .get(`${Devices}`, { params })
      .catch(catchAxiosError);

    return response;
  };

  public postDevices = async (params: {
    FacilityId: string;
    Name: string;
  }): Promise<IResponse> => {
    const response: IResponse = await this.instance
      .post(`${Devices}`, params)
      .catch(catchAxiosError);

    return response;
  };
}
